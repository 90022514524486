$('.img_realisation').slick({
	dots: false,
	infinite: true,
	speed: 300,
	slidesToShow: 3,
	slidesToScroll: 3,
	variableWidth: true,
    arrows: true,
	rows: 0,
	centerPadding: '36px',
	prevArrow:"<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><span class=\"icons-left\"></span></button>",
	nextArrow:"<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><span class=\"icons-right\"></span></button>",
	responsive: [
		{
		  breakpoint: 1024,
		  settings: {
			slidesToShow: 3,
			slidesToScroll: 3,
			infinite: true,
			
		  }
		},
		{
		  breakpoint: 600,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
			// arrows: false,
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
			// arrows: false,
		  }
		}
	  ]
});